import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/Organizational_Pic.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h3>About Us</h3>
              <p>{props.data ? props.data.top : "loading..."}</p>
              <div className="col-lg-12 col-sm-12 col-xs-12 pt-0 mt-0">
                  <ul>
                    {props.data
                      ? props.data.paragraph.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
              </div>
              <br />
              <p>{props.data ? props.data.bottom : "loading..."}</p>
              
            </div>
          </div>
        </div>
        <p>&nbsp;</p>
        <div className="row">
          <ul class="nav nav-tabs">
            <li class="active"><a data-toggle="tab" href="#our_history">Our History</a></li>
            <li><a data-toggle="tab" href="#our_mission">Our Mission</a></li>
            <li><a data-toggle="tab" href="#our_visions">Our Visions</a></li>
            <li><a data-toggle="tab" href="#our_values">Our Values</a></li>
          </ul>
          <div class="tab-content about-text">
            <div className="tab-pane fade in active col-lg-12 col-sm-12 col-xs-12" id="our_history" >
              {props.data && props.data.history ? (
                <div dangerouslySetInnerHTML={{ __html: props.data.history }} />
              ) : (
                "Loading..."
              )}
            </div>
            
            <div className="tab-pane fade col-lg-12 col-sm-12 col-xs-12" id="our_mission" >
              {props.data && props.data.mission ? (
                <div dangerouslySetInnerHTML={{ __html: props.data.mission }} />
              ) : (
                "Loading..."
              )}
            </div>
            
            <div className="tab-pane fade col-lg-12 col-sm-12 col-xs-12" id="our_visions" >            
              <ul>
                {props.data
                  ? props.data.visions.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    ))
                  : "loading"}
              </ul>
            </div>
            
            <div className="tab-pane fade col-lg-12 col-sm-12 col-xs-12" id="our_values" >
            
              <ul>
                {props.data
                  ? props.data.values.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    ))
                  : "loading"}
              </ul>
            </div>

          </div>
        </div>
        
      </div>
    </div>
  );
};
