
import React from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export const Gallery = (props) => {
  const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];
  const gallery = props.data ?? [];
  const [index, setIndex] = React.useState(-1);
  const slides = gallery.map((photo) => {    
    const width = photo.width * 4;
    const height = photo.height * 4;
    return {
      src: photo.id,
      width,
      height,
      srcSet: breakpoints.map((breakpoint) => {
        const breakpointHeight = Math.round((height / width) * breakpoint);
        return {
          src: photo.id,
          width: breakpoint,
          height: breakpointHeight,
        };
      }),
    };
  });

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
          AN VIET LONG company at Vietnam Manufacturing Expo 2016 in Ha Noi.  We are happy to present our SLA printers and latest Electrophoretic Coating technology to support industries in Vietnam.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
          <PhotoAlbum
            layout="rows"
            photos={slides}
            targetRowHeight={150}
            onClick={({ index: current }) => setIndex(current)}
          />
          
          <Lightbox
            index={index}
            slides={slides}
            open={index >= 0}
            close={() => setIndex(-1)}
          />

          </div>
        </div>
      </div>
    </div>
  );
};
