import React from "react";

export const News = (props) => {
  return (
    <div id="news" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>News</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (

                <div key={`${d.name}-${i}`} className="col-md-4 col-sm-12 team">
                  <div className="thumbnail ">
                    {" "}
                    {d.video !== ''?
                    <iframe className="news-img img-thumbnail" src={"https://www.youtube.com/embed/" + d.video} title={d.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                     :<img  src={"img/news/" + d.image} alt="..." className="news-img img-thumbnail" /> 
                    }
                    <div className="caption">
                      <h4>{d.title}</h4>                      
                    </div>
                  </div>
                </div>


                // <div key={`${d.title}-${i}`} className="col-xs-12 col-md-4">
                //   {" "}
                //   {/* <i className={d.icon}></i> */}
                //   <h3>{d.title}</h3>
                //   <p><img src={"img/news/" + d.image} className="img-responsive" alt="" /></p>
                // </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
